import { toast } from 'react-toastify';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AuthApi from '../api/authApi';
import { setCookie } from '../utils/cookiesUtils';
import Header from '../components/Header';
import qs from 'qs';
import '../css/styles.css';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import { IDataSource } from '../models/platform/dataSources';


interface Team {
  _id: string;
  name: string;
}

interface UserData {
  user_data: {
    _id: string;
    email: string;
    first_name: string;
    teams: Team[];
  };
}


interface LocationState {
  data_data_source?: IDataSource;
}

const TeamSelector: React.FC = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const history = useHistory();

  const data_source = queryParams.data_source as string;
  const redirect_uri = queryParams.redirect_uri as string;
  const state = queryParams.state as string;
  const user_data: UserData | undefined = location.state as UserData | undefined;
  const { data_data_source } = location.state as LocationState || {};

  const handleTeamSelect = async (teamId: string) => {
    const data = await selectTeam(teamId, user_data?.user_data._id, '');
    const token = data?.token;

    setCookie('looker_token', token, 1);

    
    history.push({
      pathname: '/connections',
      search: `?data_source=${encodeURIComponent(data_source)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`,
      state: { user_data, redirect_uri, state, data_data_source }, // Include data_data_source in state
    });
    
    // history.push(`/connections?display_name=${display_name}&data_source=${encodeURIComponent(data_source)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`);
  };



  const selectTeam = async (team_id: any, user_id: any, callbackUrl: any) => {
    try {
      const response = await AuthApi.selectTeam(team_id, user_id, callbackUrl);
      if (response.success) {
        const data = (response as SuccessResponse).data;

        return data;
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };



  return (
    <div>
      <Header iconUrl={data_data_source?.logo_url || ''} />
      <div className="content-container">
        <h1>Choose your everythingData team</h1>
        <h2 className="team-description">
          You belong to multiple teams, please choose which team to associate
          this report with:
        </h2>
        {user_data?.user_data.teams && user_data.user_data.teams.length === 0 ? (
          <p>No teams available.</p>
        ) : (
          <ul className="team-list">
            {user_data?.user_data.teams &&
              user_data.user_data.teams.map((team) => (
                <li
                  key={team._id}
                  onClick={() => handleTeamSelect(team._id)}
                  className="team-list-item"
                >
                  <div className="team-details">
                    <span className="team-name">{team.name}</span>
                    <span className="team-id">ID: {team._id}</span>
                  </div>
                  <span className="select-label">SELECT</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TeamSelector;
