import axios from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { API_URL } from '../config/systemConfig';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';

class AuthApi {

    private integration = 'looker' as string;
    private platform = 'connector' as string;
    
    async addLookerStudioLogin(tokenId: string) {
        try {
            const response = await axios.get(`${API_URL}/auth/looker?platform=${this.platform}&token=${tokenId}`);

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    
    async selectTeam(team_id: any, user_id: any, callbackUrl: any) {
        const data =
        {
            teamId: team_id,
            userId: user_id,
            callbackUrl: callbackUrl,
        };

        try {
            const response = await axios.post(`${API_URL}/auth/me/teams/select?platform=${this.platform}&integration=${this.integration}`, data, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    // addLookerStudioLogin(tokenId: string) {
    //     return axios.get(`${API_URL}/auth/looker?token=${tokenId}`);
    //   }
    
}

export default new AuthApi();
