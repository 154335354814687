import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import qs from 'qs';
import AuthApi from '../api/authApi';
import DataSourcesApi from '../api/dataSourcesApi';
import { setCookie } from '../utils/cookiesUtils';
import { Redirect, useHistory } from 'react-router-dom';
import { Loader } from '../components/Loader';
import Spinner from '../components/Spinner';
import { IDataSource } from '../models/platform/dataSources';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';


interface IProps {
  location: {
    search: string;
  };
}

export default function Dsconnector(props: IProps) {
  const history = useHistory();

  const [loader, setloader] = useState<boolean>(true);
  const [user_data, setUserdata] = useState<{
    token: string;
    teams?: any[];
  } | null>(null);

  const authorizeAndGetTeams = async () => {
    const { token, data_source, redirect_uri, state } = qs.parse(
      props.location.search,
      {
        ignoreQueryPrefix: true,
      }
    ) as {
      token: string;
      data_source: string;
      redirect_uri: string;
      state: string;
    };

    const user_data: any = await addLookerStudioLogin(token);
    const data_data_source = await getDataSources(data_source) as IDataSource;

    if (user_data.token) {
      setCookie('looker_token', user_data.token, 1);

      history.push({
        pathname: '/connections',
        search: `?integration=looker&data_source=${encodeURIComponent(data_source)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`,
        state: { data_data_source }, // Pass the object as state
      });

      // history.push(`/connections?display_name=${display_name}&integration=looker&data_source=${encodeURIComponent(data_source)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`);

      // history.push('/connections', {
      //   redirect_uri: redirect_uri,
      //   state: state,
      // });
    }
    else if (user_data.teams && user_data.teams.length > 1) {

      history.push({
        pathname: '/Team_Selector',
        search: `?data_source=${encodeURIComponent(data_source)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`,
        state: { user_data, redirect_uri, state, data_data_source }, // Include data_data_source in state
      });

      // history.push(`/Team_Selector?display_name=${display_name}&&data_source=${encodeURIComponent(data_source)}&redirect_uri=${encodeURIComponent(redirect_uri)}&state=${encodeURIComponent(state)}`, { user_data, redirect_uri, state });

      // history.push('/Team_Selector', { user_data, redirect_uri, state });
    }
    else {
      setUserdata(null);
    }
  };


  const addLookerStudioLogin = async (token_id: string) => {
    try {
      const response = await await AuthApi.addLookerStudioLogin(token_id)
      if (response.success) {
        const data = (response as SuccessResponse).data as IDataSource;

        return data;
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const getDataSources = async (data_source: string) => {
    try {
      const response = await await DataSourcesApi.getDataSources(data_source);
      if (response.success) {
        const data = (response as SuccessResponse).data as IDataSource;

        return data;
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };


  useEffect(() => {
    authorizeAndGetTeams();
  }, []);


  return (
    <>
      {loader ? (
        <div className="h-full flex-col flex justify-center">
          <Spinner />
        </div>
      ) : user_data === null ? (
        <div className="bg-gray-50 h-screen">
          <div className="flex justify-center items-center flex-col py-10">
            <p className="text-red-500">Authentication issue. No token or teams data.</p>
          </div>
        </div>
      ) : null}
    </>
  );
}
