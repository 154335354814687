import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Connections from './pages/Connections';
import Dsconnector from './pages/Dsconnector';
import Team_Selector from './pages/Team_Selector';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/dsconnector" component={Dsconnector} exact={true} />
          <Route path="/connections" component={Connections} exact={true} />
          <Route path="/team_selector" component={Team_Selector} exact={true} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
